@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply font-mono h-full bg-gray-800;
}

.app-link {
  @apply text-primary-601 hover:text-primary-801 underline font-normal cursor-pointer;
}

.app-prose {
  @apply prose prose-h2:font-medium prose-h2:mb-2;
}

.app-prose a {
  @apply app-link;
}
